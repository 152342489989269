import request from '@/utils/request';

// 查询员工
export function listStaff(query) {
  return request({
    url: '/staff/list',
    method: 'get',
    params: query,
  });
}

// 新增员工
export function addStaff(data) {
  return request({
    url: '/staff/add',
    method: 'post',
    data: data,
  });
}

// 修改员工
export function editStaff(data) {
  return request({
    url: '/staff/edit',
    method: 'post',
    data: data,
  });
}

// 删除员工
export function deleteStaff(data) {
  return request({
    url: '/staff/delete',
    method: 'post',
    data: data,
  });
}

// 密码修改
export function changePassword(data) {
  return request({
    url: '/user/changePassword',
    method: 'post',
    data: data,
  });
}

// 个人中心信息
export function userStaffInfo(query) {
  return request({
    url: '/user/info',
    method: 'GET',
    params: query,
  });
}

// 导入员工
export function importStaff(data) {
  return request({
    headers: {
      repeatSubmit: false,
    },
    url: '/staff/import',
    method: 'post',
    data: data,
  });
}

// 视频播放详情
export function playDetail(data) {
  return request({
    url: '/user/playDetail',
    method: 'GET',
    params: data,
  })
}

// 登录信息，跟登录接口的返回值一样
export function loginInfo(query) {
  return request({
    url: '/user/loginInfo',
    method: 'GET',
    params: query,
  });
}