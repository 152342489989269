import {
  createWebHistory,
  createWebHashHistory,
  createRouter,
} from 'vue-router';
/* Layout */
import Layout from '@/layout';

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/third_login',
    component: () => import('@/views/third_login'),
    hidden: true,
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true,
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true,
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    redirect: '/profile',
    children: [
      {
        path: 'profile',
        component: () => import('@/views/system/user/profile/index'),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user' },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/profile/resetPwd',
        component: () => import('@/views/system/user/profile/resetPwd'),
        name: 'resetPwd',
        meta: { title: '修改密码', icon: 'user' },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: '/videoList',
        hidden: false,
        component: () => import('@/views/backend/videoList/index'),
        name: 'videoList',
        meta: { title: '视频列表', icon: 'list' },
      },
      
    ],
  },
  // {
  //   path: '',
  //   component: Layout,
  //   hidden: JSON.parse(localStorage.getItem('user'))?.isManager ? false : true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: '/videoManagement',
  //       component: () => import('@/views/backend/videoManagement/index'),
  //       name: 'videoManagement',
  //       meta: { title: '视频管理', icon: 'redis' },
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   component: Layout,
  //   hidden: JSON.parse(localStorage.getItem('user'))?.isManager === 1 ? false : true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: '/userManagement',
  //       component: () => import('@/views/backend/userManagement/index'),
  //       name: 'userManagement',
  //       meta: { title: '员工管理', icon: 'user' },
  //     },
  //   ],
  // },
];

// 动态路由，基于用户权限动态去加载
// 可以菜单管理隐藏页面 MENU_PATH_INLINE 标识，activeMenu激活菜单
export const dynamicRoutes = [
  {
    path: '',
    component: Layout,
    hidden: false,
    permissions: ['system:user:admin'],
    children: [
      {
        path: '/videoManagement',
        component: () => import('@/views/backend/videoManagement/index'),
        name: 'videoManagement',
        meta: { title: '视频管理', icon: 'monitor' },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    hidden: false,
    permissions: ['system:user:admin'],
    children: [
      {
        path: '/operatingSOP',
        component: () => import('@/views/backend/operatingSOP/index'),
        name: 'operatingSOP',
        meta: { title: '作业指导', icon: 'druid' },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    hidden: false,
    permissions: ['system:user:admin'],
    children: [
      {
        path: '/topicLibrary',
        component: () => import('@/views/backend/topicLibrary/index'),
        name: 'topicLibrary',
        meta: { title: '专题课程', icon: 'redis' },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    hidden: false,
    permissions: ['system:user:admin'],
    children: [
      {
        path: '/growup',
        component: () => import('@/views/backend/growup/index'),
        name: 'growup',
        meta: { title: '新员工成长', icon: 'druid' },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    hidden: false,
    permissions: ['system:user:admin'],
    children: [
      {
        path: '/promote',
        component: () => import('@/views/backend/promote/index'),
        name: 'promote',
        meta: { title: '管理提升', icon: 'druid' },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    hidden: false,
    permissions: ['system:user:admin', 'leader'],
    children: [
      {
        path: '/userManagement',
        component: () => import('@/views/backend/userManagement/index'),
        name: 'userManagement',
        meta: { title: '员工管理', icon: 'user' },
      },
    ],
  },
  {
    path: '/t/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:user:edit'],
    children: [
      {
        path: 'role/:userId(\\d+)',
        component: () => import('@/views/system/role/auth-user'),
        name: 'AuthRole',
        meta: { title: '分配角色', activeMenu: '/system/user' },
      },
    ],
  },
  {
    path: '/t/system/role-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:role:edit'],
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: () => import('@/views/system/role/auth-user'),
        name: 'AuthUser',
        meta: { title: '分配用户', activeMenu: '/system/role' },
      },
    ],
  },
  {
    path: '/t/system/dict-data',
    component: Layout,
    hidden: true,
    permissions: ['system:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/system/dict/data'),
        name: 'Data',
        meta: { title: '字典数据', activeMenu: '/system/dict' },
      },
    ],
  },
  {
    path: '/t/monitor/job-log',
    component: Layout,
    hidden: true,
    permissions: ['monitor:job:list'],
    children: [
      {
        path: 'index/:jobId(\\d+)',
        component: () => import('@/views/monitor/job/log'),
        name: 'JobLog',
        meta: { title: '调度日志', activeMenu: '/monitor/job' },
      },
    ],
  },
  {
    path: '/t/tool/gen-edit',
    component: Layout,
    hidden: true,
    permissions: ['tool:gen:edit'],
    children: [
      {
        path: 'index/:tableId(\\d+)',
        component: () => import('@/views/monitor/job/log'),
        name: 'GenEdit',
        meta: { title: '修改生成配置', activeMenu: '/tool/gen' },
      },
    ],
  },
];

// 根据.env配置获取是否带井号和基础路径
const hasHash = import.meta.env.VITE_HISTORY_HASH;
const bashUrl = import.meta.env.VITE_HISTORY_BASE_URL;

const router = createRouter({
  history:
    hasHash === 'true'
      ? createWebHashHistory(bashUrl)
      : createWebHistory(bashUrl),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

import throttle from 'lodash/throttle';
import { loginInfo } from '@/api/backend/user';
const throttleLoginInfo = throttle(async (value) => {
  loginInfo({refresh: 1}).then(res => {
    // copy from src\store\modules\user.js
    const data = {
      isManager: res.data.isManager,
      phone: res.data.phone,
      userName: res.data.name,
      role: res.data.role,
      allowChangeUser: res.data.allowChangeUser,
    }
    localStorage.setItem("user", JSON.stringify(data));
  });
}, 1000, { trailing: false });
router.beforeEach((to, from, next) => {
  const notGetInfo = ['/login', '/profile'];
  if (notGetInfo.indexOf(to.path) === -1) {
    throttleLoginInfo();
  }
  
  next();
})

export default router;
